:local(.filterItemContainer) {
  padding-left: 0.75em;

  :local(.filterItem) {
    cursor: pointer;
    margin-bottom: 8px;
    > a {
      display: flex;
      text-decoration: none;
      color: #000;
    
    }

    span {
      margin-right: 6px;
    }
  }
}
