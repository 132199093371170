@import "../../style/abstracts/all";

:local(.buttonRow) {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  > * {
    button {
      margin-right: 0;
    }
  }
}

.marginBottom {
  margin-bottom: 20px;
}

:local(.listItem) {
  display: block;
  font-size: 1.2em;
  border-top: 1px solid #e5e3e1;
  padding: 15px 10px;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: #e5e3e1;
  }
  &:first-of-type {
    border-top-color: transparent;
  }
}
