.validDate {
    padding: 12px;
    border: none;
    background: #fff;
    margin: 0;
    .hidden {
        visibility: hidden;
        height: 0;
        width: 0;
    }
    .descriptiondate {
        font-size: 0.8em;
        margin: 5px 0;
    }
}
