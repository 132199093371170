.marginBottomSmall {
    margin-bottom: 10px;
}

.inlineInputs {
    display: flex;
    align-items: flex-end;

    > div {
        flex: 1;

        &:not(:last-child) {
            margin-right: 12px;
        }

        > div {
            width: 100%;
        }
    }
}

.actionListItemButton {
    text-align: left;
    font-size: initial;
    border: none;
    background: none;
    padding: 14px 8px;
    width: 100%;
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    &:not(:last-of-type) {
        border-bottom: 1px solid #e0e0e0;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.5);
    }
    .badge {
        background-color: #072938;
        color: #fff;
        border-radius: 10px;
        padding: 0 8px;
    }
}
