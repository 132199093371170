.taelk {
   width: 190px;
   display: block;
   position: absolute;
   right: 0;
   bottom: 0;
}

.static-metadata-filters {
   width: 100%;
   margin-bottom: 15px;
   display: flex;
   flex-direction: column;

   .static-metadata-filters-header {
      //font-weight: bold;
      display: flex;

      > div {
         align-self: flex-end;
      }
   }

   .static-metadata-filter {
      display: flex;
   }

   .static-metadata-filters-header,
   .static-metadata-filter {
      > div {
         padding: 5px 10px;

         &:first-child {
            padding-left: 0;
         }

         &:last-child {
            padding-right: 0;
         }
      }
   }
   
   .static-metadata-filters-header,
   .static-metadata-filter {
      > div:nth-child(1) {
         width: 20%;
      }

      > div:nth-child(2) {
         width: 45%;
      }

      > div:nth-child(3) {
         width: 17.5%;
      }

      > div:nth-child(4) {
         width: 17.5%;
      }
   }

   .static-metadata-filters-header {
      > div:nth-child(3),
      > div:nth-child(4) {
         text-align: center;
      }
   }

   .static-metadata-filter {
      > div:nth-child(3),
      > div:nth-child(4) {
         display: flex;
         align-items: center;
         justify-content: center;

         label {
            margin: 0;

            span {
               margin: 0;
            }

            &:focus-within {
               border: 0;
               outline: 0;
            }
         }
      }
   }
}
