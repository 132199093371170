:local(.ullist) {
  list-style: none;
  padding-left: 0;
  > li {
    margin: 0 0 5px 0;
    border-bottom: 1px solid #eee;
    margin-left: 0;
    padding: 5px;
    text-transform: capitalize;
  }
}
:local(.listing) {
  list-style: none;
  padding-left: 0;
  > li {
    margin: 0 0 5px 0;
    margin-left: 0;
    padding: 5px;
    text-transform: capitalize;
  }
}
