$sortable_handle_width: 30px;

@function calcColumnWidth($percentage) {
   @return calc((100% - $sortable_handle_width) * $percentage / 100);
}

.dynamic-metadata-table {
   width: 100%;
   margin-bottom: 30px;
   display: flex;
   flex-direction: column;

   .dynamic-metadata-header {
      display: flex;

      > div {
         align-self: flex-end;
      }
   }

   .dynamic-metadata {
      display: flex;
   }

   .dynamic-metadata-header,
   .dynamic-metadata {
      > div {
         padding: 5px 10px;

         &:first-child {
            padding-left: 0;
         }

         &:last-child {
            padding-right: 0;
         }
      }
   }

   .dynamic-metadata-header,
   .dynamic-metadata {
      > div:nth-child(1) {
         width: $sortable_handle_width;
         padding: 0;
         display: flex;
         align-items: center;
      }

      > div:nth-child(2) {
         width: calcColumnWidth(20);
      }

      > div:nth-child(3) {
         width: calcColumnWidth(11);
      }

      > div:nth-child(4) {
         width: calcColumnWidth(11);
      }

      > div:nth-child(5) {
         width: calcColumnWidth(15);
      }

      > div:nth-child(6) {
         width: calcColumnWidth(11);
      }

      > div:nth-child(7) {
         width: calcColumnWidth(11);
      }

      > div:nth-child(8) {
         width: calcColumnWidth(21);
      }
   }

   .dynamic-metadata-header {
      > div:nth-child(3),
      > div:nth-child(6),
      > div:nth-child(7) {
         text-align: center;
      }
   }

   .dynamic-metadata {
      > div:nth-child(3),
      > div:nth-child(6),
      > div:nth-child(7) {
         display: flex;
         align-items: center;
         justify-content: center;

         label {
            margin: 0;

            span {
               margin: 0;
            }

            &:focus-within {
               border: 0;
               outline: 0;
            }
         }
      }
   }

   .dynamic-metadata > div:nth-child(8) {
      display: flex;
      align-items: center;
   }

   .handle {
      background: url("../../../../assets/images/icon-sortable.svg") no-repeat center;
      background-size: 24px;
      width: 24px;
      height: 24px;
      cursor: grab;
   }
}
