:local(.filterSection) {
  padding: 12px;
  border: none;
  background: #fff;
  > div {
    border: none; // TODO: Fix in dibk-design package
    padding: 0;
    > h4 {
      font-style: normal !important; // TODO: Fix in dibk-design package
    }
  }
  :local(.facetFilterItemsContainer) {
    margin: 1em 0;
  }
}
:local(.helpicon) {
  color: #00757f;
  font-size: 1.5em;
  display: inline-block;
  margin: -5px 10px;
  &:hover {
    cursor: pointer;
  }
}
