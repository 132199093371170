:local(.activityDetailsField) {
  padding: 10px 0;

  input[type="text"],
  input[type="date"] {
    width: 100%;
  }
  > label {
    cursor: pointer;
    font-size: 18px;
    margin: 0 0 4px;
    font-family: "Open Sans", arial, sans-serif;
    line-height: 1.3;
    font-weight: bold;
    font-style: normal;
    display: block;
  }
}

:local(.infoBox) {
  padding: 16px;
  background-color: #ecf2f6;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: inline-block;
  }

  :local(.icon) {
    display: inline-block;
    margin-right: 8px;
    content: "";
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: left center;
    height: 40px;
    width: 40px;
  }
}

:local(.actionButtons) {
  display: flex;

  button {
    flex: 1;

    &:local(.warningButton) {
      background-color: orange !important;
    }
  }
}

:local(.inlineInputs) {
  display: flex;
  align-items: flex-end;

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 12px;
    }

    > div {
      width: 100%;
    }
  }
  button {
    margin: 0;
  }
}

:local(.modalContentContainer) {
  padding: 24px 28px;
}
