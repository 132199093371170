.expand-button {
   display: flex;
   align-items: center;      
   font-size: 17px;
   font-style: normal;   
   cursor: pointer;
   user-select: none;

   &::after {
      content: "";
      background: url("../../../assets/images/icon-arrow.svg") no-repeat center;
      background-size: 8px;
      width: 15px;
      height: 15px;
      margin-left: 5px;
      transition: all 0.25s ease;
      transform: rotate(90deg);
   }

   &.expanded::after {
      transform: rotate(-90deg);
   }
}
