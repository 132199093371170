$sortable_handle_width: 30px;

@function calcColumnWidth($percentage) {
   @return calc((100% - $sortable_handle_width) * $percentage / 100);
}

.dynamic-metadata-values-container {
   margin: 15px 0 30px 0;
   display: none;

   &.expanded {
      display: block;
   }

   > div {
      display: flex;
      flex-direction: column;
      margin: 15px 0 30px 0;

      .dynamic-metadata-values-table {
         margin-bottom: 15px;

         .dynamic-metadata-values-table-header {
            display: flex;

            > div {
               align-self: flex-end;
            }
         }

         .dynamic-metadata-values-table-row {
            display: flex;
         }

         .dynamic-metadata-values-table-header,
         .dynamic-metadata-values-table-row {
            > div {
               padding: 5px 10px;

               &:first-child {
                  padding-left: 0;
               }

               &:last-child {
                  padding-right: 0;
               }
            }

            > div:nth-child(1) {
               width: $sortable_handle_width;
               padding: 0;
               display: flex;
               align-items: center;
            }

            > div:nth-child(2) {
               width: calcColumnWidth(60);
            }

            > div:nth-child(3) {
               width: calcColumnWidth(15);
            }

            > div:nth-child(4) {
               width: calcColumnWidth(25);
               display: flex;
               align-items: center;
            }
         }
      }
   }
}

.handle {
   background: url("../../../../assets/images/icon-sortable.svg") no-repeat center;
   background-size: 24px;
   width: 24px;
   height: 24px;
   cursor: grab;
}