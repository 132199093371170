@import "../../style/abstracts/all";

:local(.activityDetailsContainer) {
  ::-webkit-scrollbar {
    background-color: #fff;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border: 5px solid #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  img {
    max-width: 100%;
  }

  &:local(.sidebarView) {
    height: 100vh;
    position: relative;
    width: 100%;
    hyphens: auto;
    padding: 16px;
    :local(.activityDetailsHeader) {
      font-weight: normal;
      z-index: 1;
      position: relative;
      @include transition(box-shadow $default-transition);
      :local(.activityDetailsHeaderTitle) {
        font-size: 1.2em;
        display: inline-block;
        padding: 10px 0 14px;
      }
      &.shadow {
        box-shadow:
          0px 1px 1px -1px rgba(0, 0, 0, 0.05),
          0px 3px 3px -3px rgba(0, 0, 0, 0.25);
      }
    }

    :local(.activityDetailsContent) {
      height: calc(100% - 84px);
      position: absolute;
      overflow-y: scroll;
      overflow-x: hidden;

      &:local(.isLoggedIn) {
        height: calc(100% - 138px);
      }

      &:local(.draft) {
        background-color: rgba(255, 165, 0, 0.2);
      }
    }

    :local(.actionButtons) {
      @include transition(box-shadow $default-transition);
      z-index: 1;
      position: absolute;
      background-color: #fff;
      width: calc(100% - 32px);
      bottom: 0;
      padding: 10px 0;
      &.shadow {
        box-shadow:
          0px -1px 1px -1px rgba(0, 0, 0, 0.05),
          0px -3px 3px -3px rgba(0, 0, 0, 0.25);
      }
    }
  }

  :local(.activityDetailsContent) {
    :local(.activityDetailsField) {
      padding: 10px 0;
      input[type="text"] {
        width: 100%;
      }
      > label {
        cursor: pointer;
        font-size: 18px;
        margin: 0 0 4px;
        line-height: 1.3;
        font-weight: bold;
        font-style: normal;
        display: block;
      }
    }
  }

  :local(.actionButtons) {
    display: flex;

    button {
      flex: 1;
      margin-bottom: 0;
      &:local(.warningButton) {
        background-color: orange !important;
      }
    }
  }

  :local(.inlineInputs) {
    display: flex;
    align-items: flex-end;

    > div {
      flex: 1;

      &:not(:last-child) {
        margin-right: 12px;
      }

      > div {
        width: 100%;
      }
    }
  }
  :local(button.unstyled) {
    background: none;
    border: none;
    font-size: inherit;
    color: inherit;
    text-align: inherit;
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
  :local(.expandableList) {
    :local(.expandableListHeader) {
      line-height: 1.3;
      font-size: 18px;
      margin: 0 0 4px;
      font-weight: bold;
      cursor: pointer;

      :local(.expandButton) {
        margin-right: 5px;
      }
    }

    :local(.expandableListContent) {
      > button {
        display: none;
      }

      &:not(:local(.expanded)) {
        display: none;
      }
      &:local(.expanded) {
        > label {
          border-bottom: 1px solid #eee;
          padding: 5px;
          margin: 0 0 5px;

          &:local(.inlineInputs) {
            display: flex;
            align-items: flex-end;

            > div {
              flex: 1;
            }

            > * {
              margin: 6px;
            }
          }
        }

        > button {
          display: block;
        }
      }
    }
  }

  :local(.loadingAnimationContainer) {
    background: rgba(255, 255, 255, 0.75);
    height: 100%;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 75px;
      height: 75px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
:local(.modalContentContainer) {
  padding: 24px 28px;
  > button:last-of-type {
    float: right;
  }
  :local(.btnAdd) {
    background-color: violet;
  }
}

:local(.navigateToTop) {
  button {
    display: block;
    background-color: #072938;
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
    &:focus,
    &:active {
      clip: auto;
      width: 100%;
      height: auto;
      bottom: 0;
      span {
        z-index: 100;
        transition: none;
        text-decoration: none;
      }
    }
  }
}
