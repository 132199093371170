@import "../../style/abstracts/all";

.admin {
   display: flex;
   flex-wrap: wrap;

   .admin-left-content {
      width: 100%;
      top: 20px;
      max-height: 0;
      overflow: hidden;
      margin-bottom: 12px;
      transition: max-height 0.3s ease-in-out 0s;
      &.expanded {
         max-height: none;
      }
      @media screen and (min-width: $screen-md) {
         width: 340px;
         height: 100vh;
         max-height: none;
         overflow: hidden scroll;
         position: sticky;
         margin-bottom: 0;
      }

      .menu-section {
         padding: 12px;
         border: none;
         background: #fff;
      }

      .admin-menu a {
         border: 2px solid transparent;

         &.active {
            font-weight: bold;
         }
      }
   }

   .admin-right-content {
      margin: 0 auto;
      width: 100%;

      @media screen and (min-width: $screen-md) {
         margin: 0 0 0 24px;
         width: calc(100% - 364px);
      }

      h1 {
         font-family: "Altis-Light", "Open Sans", arial, sans-serif !important;
         font-size: 50px;
         font-weight: 100;
         margin: 0 0 25px 0;
         line-height: 50px;
      }
   }

   .expandButton {
      outline-color: rgb(0, 48, 69);
      color: rgb(0, 82, 117);
      background: rgb(235, 244, 250);
      width: 100%;
      margin: 0px auto 12px;
      background: transparent;
      color: rgb(0, 48, 69);
      border: 1px solid rgb(0, 48, 69);
      border-radius: 0px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.01em;
      padding: 12px 24px;
      cursor: pointer;
      display: block;
      @media screen and (min-width: $screen-md) {
         display: none;
      }
      @media print {
         display: none;
      }
   }
}

.Toastify .checklist-toast {
   box-shadow: 0 2px 3px rgb(0 0 0 / 18%);
   border-radius: 0;
   font-family: inherit;
   user-select: none;
}
