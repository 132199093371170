:local(.filter) {
  hyphens: auto;

  label {
    font-weight: normal;
  }
  > .userFeedBackContainer {
    background-color: #fff;
  }
  > fieldset {
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    > legend {
      background-color: #fff;
    }
    legend {
      display: block;
      padding: 24px;
      margin: 0 0 4px 0;
      width: 100%;
      hyphens: initial;
    }
    fieldset {
      legend {
        display: contents;
      }
    }
  }

  .infoBox {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    > span {
      display: inline-block;
      &:hover {
        cursor: pointer;
      }
      &.textcontent {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
      }
      &.textcontentOpen {
        padding: 12px;
        height: 110px;
        transition: height 0.3s ease-in-out;
      }
    }

    .feedbackButton {
      border: none;
      cursor: pointer;
    }
  }
}
