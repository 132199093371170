:local(.postModalOverlay) {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
  background: rgba(0,0,0,.75);
  height: 100%;
  top: 0;
  left: 0;
  align-content: center;
  padding: 36px;
  z-index: 2;
  justify-content: center;




  :local(.postModalContent) {
    max-width: 540px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12);
    border-radius: 4px;
    overflow: auto;
    width: calc(100% - 128px);
    max-height: 100%;
  }
}
