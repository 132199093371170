.filter {
   .filter-header {
      display: inline-flex;
      user-select: none;
      cursor: pointer;
   }

   h4 {
      display: flex;
      align-items: center;

      &::after {
         content: "";
         background: url("../../../../assets/images/icon-arrow.svg") no-repeat center;
         background-size: 8px;
         width: 15px;
         height: 15px;
         margin-left: 5px;
         transition: all 0.25s ease;
      }
   }

   .facets-container {
      display: none;
   }

   &.filter-expanded {
      h4::after {
         transform: rotate(90deg);
      }

      .facets-container {
         display: block;
      }
   }
}

.facets-container {
   margin: 15px 0 30px 0;

   .facets {
      display: flex;
      flex-direction: column;
      margin: 15px 0 30px 0;

      .facets-table {
         margin-bottom: 15px;

         .facets-header {
            font-weight: bold;
            display: flex;

            > div {
               align-self: flex-end;
            }
         }

         .facets-header,
         .facet {
            > div {
               padding: 5px 10px;

               &:first-child {
                  padding-left: 0;
               }

               &:last-child {
                  padding-right: 0;
               }
            }

            > div:nth-child(1) {
               width: 24px;
               padding: 0;
               display: flex;
               align-items: center;
            }

            > div:nth-child(2) {
               width: 25%;
            }

            > div:nth-child(3) {
               width: 51%;
            }

            > div:nth-child(4) {
               width: 20%;
               display: flex;
               align-items: center;
            }
         }

         .facet {
            display: flex;

            .handle {
               background: url("../../../../assets/images/icon-sortable.svg") no-repeat center;
               background-size: 24px;
               width: 24px;
               height: 24px;
               cursor: grab;
            }
         }
      }
   }
}

.mb-10 {
   margin-bottom: 10px;
}