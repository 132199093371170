@import "./style/abstracts/_all.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $default-font;
  background: $body-background;
  color: var(--color-default-text, $color-default-text);
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media print {
    background: none;
  }
  &.no-scroll {
    overflow: hidden;
    margin-right: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  background-color: var(--body-background, $body-background);
  width: 16px;
}
::-webkit-scrollbar-track {
  background-color: var(--body-background, $body-background);
}
::-webkit-scrollbar-track:hover {
  outline: 1px outset rgba(0, 0, 0, 0.15);
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid var(--body-background, $body-background);
  margin-top: -10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid var(--body-background, $body-background);
}
::-webkit-scrollbar-button {
  display: none;
}
