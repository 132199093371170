@import "../../style/abstracts/all";

.mainContainer {
  display: flex;
  flex-wrap: wrap;
  .filterContainer {
    width: 100%;
    top: 20px;
    max-height: 0;
    overflow: hidden;
    margin-bottom: 12px;
    &.expanded {
      max-height: none;
    }
    @media screen and (min-width: $screen-md) {
      width: 340px;
      height: 100vh;
      max-height: none;
      overflow: hidden scroll;
      position: sticky;
      margin-bottom: 0;
    }
  }

  .checklistContainer {
    margin: 0 auto;
    width: 100%;
    @media print {
      height: auto;
      overflow: auto;
    }
    @media screen and (min-width: $screen-md) {
      margin: 0 0 0 24px;
      width: calc(100% - 364px);
    }
    .checklistContainerHeader {
      margin-bottom: 30px;
    }
  }



  .expandButton {
    outline-color: rgb(0, 48, 69);
    color: rgb(0, 82, 117);
    background: rgb(235, 244, 250);
    width: 100%;
    margin: 0px auto 12px;
    background: transparent;
    color: rgb(0, 48, 69);
    border: 1px solid rgb(0, 48, 69);
    border-radius: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 12px 24px;
    cursor: pointer;
    display: block;
    @media screen and (min-width: $screen-md) {
      display: none;
    }
    @media print {
      display: none;
    }
  }
  .description {
    padding: 0 0 25px 0;
  }
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}
