@import "../../style/abstracts/all";

:local(.rightContainer) {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: $screen-sm) {
        position: relative;
        right: -20px;
    }
    :local(.navigationBarText) {
        display: inline-block;
        flex: 1;
        font-size: 16px;
        margin: 12px -16px;
        @media only screen and (min-width: $screen-sm) {
            text-align: right;
            margin: 0;
        }
        > span {
            display: block;
            a {
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }

    button {
        font-family: $default-font;
        position: absolute;
        top: 10px;
        right: 10px;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-primary;
            color: #fff;
        }
        @media only screen and (min-width: $screen-sm) {
            position: relative;
            top: 0;
            right: 0;
            margin-right: 0;
            margin-left: 20px;
        }
        .textLargeScreen {
            display: none;
            @media only screen and (min-width: $screen-sm) {
                display: inline-block;
            }
        }
        .textSmallScreen {
            display: inline-block;
            @media only screen and (min-width: $screen-sm) {
                display: none;
            }
        }
    }
}
