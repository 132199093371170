@import "../../../../style/abstracts/all";

.listItem {
  display: flex;
  flex-direction: row;
  font-size: 1.1em;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  border-color: transparent;

  &.isVisible {
    max-height: 400px;

    @media print {
      border-width: 0px;
    }

    &.hasSelectedActionValue {
      max-height: none;
    }
    .listContent {
      border-bottom: 1px solid var(--color-border, $color-border);
    }
  }

  .listContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    &:hover {
      background-color: var(--color-secondary-x-light, $color-secondary-x-light);
    }
  }

  :local(.noExpandButtonSpacer) {
    display: inline-block;
    width: 18px;
  }

  :local(.radioButton) {
    padding: 10px;
    margin: 10px 0;
    display: inline-block;
    cursor: pointer;

    span {
      margin-right: 6px;
    }
  }
}
:local(.listItemAttributes) {
  display: block;
  font-size: 0.9em;
}
:local(.activityType) {
  font-size: 1.3em;
  margin-top: 10px;
}
:local(.auto) {
  color: green;
}
:local(.utsjekk) {
  color: yellowgreen;
}
:local(.vurdering) {
  color: brown;
}
:local(.hjelp) {
  color: orangered;
}

:local(.action) {
  border-radius: 2px;
  border: 1px solid #333;
  padding: 10px;
  margin: 5px 0;
  background-color: white;
}

:local(.content) {
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  padding: 15px 10px 15px 0;

  &:local(.hasOnlySubActivityMatch) {
    opacity: 0.5;
  }

  :local(.listItemName) {
    display: flex;
    justify-content: space-between;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    :local(.matchPointsSearchIcon) {
      width: 1.5em;
      height: 1.5em;
      color: #aaa;
    }
  }

  :local(.listItemDescription) {
    font-size: 0.8em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  :local(.listItemInfo) {
    font-size: 0.8em;
    padding: 6px 0;
  }
  :local(fieldset) {
    border: none;
  }
}

:local(.expandBtnContainer) {
  text-align: center;
  border-left: 4px solid var(--color-border, $color-border);
  padding: 14px 10px;
  @media print {
    border-left: none;
  }

  &:hover {
    cursor: pointer;
  }
  button {
    border: none;
    background: none;
    width: 20px;
    height: 20px;
  }

  &:local(.draft) {
    border-color: orange;
  }
}
:local(.expandBtn) {
  background: transparent;
  border: none;
}
:local(.expandBtn) {
  cursor: pointer;

  > svg {
    margin: 0 10px;
  }
}
:local(.btnAdd) {
  background-color: #e9e7e5;
  border-radius: 100%;
  padding: 0;
  color: #00757f;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
}
:local(.smallBtnContainer) {
  display: inline-block;
  width: 100%;
  text-align: right;
}
:local(.actiondescr) {
  font-style: italic;
  font-size: 0.8em;
  > label {
    font-style: normal;
    font-weight: normal;
  }
  > h2 {
    margin: 0;
    font-size: 1em;
  }
}

:local(.srOnly) {
  height: 0;
  width: 0;
  position: relative;
  overflow: hidden;
}
